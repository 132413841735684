import React from "react";
import { Link } from "gatsby";
// import ReactMarkdown from "react-markdown";

const Footer = (props) => {
  return (
    <div className="footer py-6">
      <div className="container">
        <div className="row">
          {props &&
            props.menu &&
            props.menu.map(({ node }, index) => (
              <div key={index} className="col-12 col-md-3 mb-2">
                <Link to={node.url}>
                  <strong className="text-white">{node.name}</strong>
                </Link>
                {node?.submenu?.map((val, index2) => (
                  <div key={index2}>
                    {val.url.includes("http") ? (
                      <a href={val.url} target="_blank">
                        <div className="text-white">{val.name}</div>
                      </a>
                    ) : (
                      <Link to={val.url}>
                        <div className="text-white">{val.name}</div>
                      </Link>
                    )}

                    {val.anchors?.map((anchor, id3) => (
                      <div key={id3} className="ml-1">
                        <Link to={anchor.url}>
                          <div className="text-white">{anchor.name}</div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          {/* <div className="col-4">TEST</div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
