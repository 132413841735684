import React from "react";
import { iconService } from "../data/icon";
import LanguageSelector from "./LanguageSelector";
import MenuItems from "./MenuItems";

const Menu = ({ lang, menu, service, slug }) => {
  return (
    <nav id="main-menu" className="main-menu py-2 ">
      <ul className="menus">
        {menu &&
          menu.map(({ node }, index) => {
            // console.log("NODE MENU", node);
            const depthLevel = 0;
            return <MenuItems items={node} key={index} depthLevel={depthLevel} />;
          })}
        {/* <span className="pl-1 border-left border-primary"> */}
        {service &&
          service.map(({ node }, index) => {
            // console.log("NODE MENU", node);
            const depthLevel = 0;
            return <MenuItems items={node} key={index} depthLevel={depthLevel} />;
          })}
        {/* </span> */}

        <LanguageSelector lang={lang} slug={slug} />
      </ul>
    </nav>
  );
};

export default Menu;
