import React from "react";
import { Link } from "gatsby";
// import MenuItemsMobile from "./MenuItemsMobile";
import LanguageSelector from "./LanguageSelector";

const MobileMenu = ({ lang, menu, service, active }) => {
  return (
    <div id="main-menu-mobile" className={`main-menu-mobile ${active ? "open" : ""}`}>
      {/* <ul>
        {menu &&
          menu.map(({ node }, index) => {
            // console.log("NODE MENU", node);
            const depthLevel = 0;
            return <MenuItemsMobile items={node} key={index} depthLevel={depthLevel} />;
          })}
      </ul> */}
      <ul>
        <div className="container">
          <div className="row">
            {menu &&
              menu.map(({ node }, index) => (
                <div key={index} className="col-12 col-md-3">
                  <li>
                    <Link to={node.url}>
                      <strong className="text-white">{node.name}</strong>
                    </Link>
                    {node?.submenu?.map((val, index2) => (
                      <div key={index2}>
                        {val.url.includes("http") ? (
                          <a href={val.url} target="_blank">
                            <div className="text-white">{val.name}</div>
                          </a>
                        ) : (
                          <Link to={val.url}>
                            <div className="text-white">{val.name}</div>
                          </Link>
                        )}

                        {val.anchors?.map((anchor, id3) => (
                          <div key={id3} className="ml-1">
                            <Link to={anchor.url}>
                              <div className="text-white">{anchor.name}</div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    ))}
                  </li>
                </div>
              ))}
            {service &&
              service.map(({ node }, index) => (
                <div key={index} className="col-12 col-md-3">
                  <li>
                    <Link to={node.url}>
                      <strong className="text-white">{node.name}</strong>
                    </Link>
                    {node?.submenu?.map((val, index2) => (
                      <div key={index2}>
                        {val.url.includes("http") ? (
                          <a href={val.url} target="_blank">
                            <div className="text-white">{val.name}</div>
                          </a>
                        ) : (
                          <Link to={val.url}>
                            <div className="text-white">{val.name}</div>
                          </Link>
                        )}

                        {val.anchors?.map((anchor, id3) => (
                          <div key={id3} className="ml-1">
                            <Link to={anchor.url}>
                              <div className="text-white">{anchor.name}</div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    ))}
                  </li>
                </div>
              ))}
            <div className="col-12 col-md-3">
              <li>
                <span>
                  <Link
                    className="text-white"
                    to={lang == "default" ? "#" : "/"}
                    style={lang == "default" ? { fontWeight: "bold" } : {}}
                  >
                    ITA
                  </Link>
                </span>{" "}
                <span>
                  <Link
                    className="text-white"
                    to={lang == "en" ? "#" : "/en"}
                    style={lang == "en" ? { fontWeight: "bold" } : {}}
                  >
                    ENG
                  </Link>
                </span>
              </li>
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default MobileMenu;
