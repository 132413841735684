// import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Link } from "gatsby";

const isBrowser = typeof window !== "undefined";

function LanguageSelector({ lang, slug }) {
  const [dropdown, setDropdown] = useState(false);
  const onMouseEnter = () => {
    if (window !== "undefined") {
      // dropdown && setDropdown(false);
      setDropdown(false);
      window.innerWidth > 960 && setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window !== "undefined") {
      // const newLocal = 15000;
      if (window.innerWidth > 960) {
        // setTimeout(() => {
        setDropdown(false);
        // }, 100);
      }
    }
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      <div className="">
        <button
          type="button"
          aria-haspopup="menu"
          aria-expanded={dropdown ? "true" : "false"}
          onClick={() => setDropdown((prev) => !prev)}
        >
          {isBrowser ? (
            <Link to="#" activeClassName="activeLink">
              {lang === "default" ? "Ita" : "Eng"}
            </Link>
          ) : null}
          <span className="float-right arrow" />
        </button>
        <ul
          className={`dropdown-menu dropdown-submenu ${dropdown ? "show" : ""}`}
          style={{ maxWidth: "2rem" }}
        >
          {isBrowser ? (
            <li
              className="menu-items"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={closeDropdown}
            >
              <Link
                to={
                  lang === "default"
                    ? "/en" + slug
                    : slug.split("/").join("") == "en"
                    ? "/"
                    : slug
                }
                activeClassName="activeLink"
              >
                {lang === "default" ? "Eng" : "Ita"}
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    </li>
  );
}

export default LanguageSelector;
