import React from "react";
import { Link } from "gatsby";

const SubFooter = (props) => {
  const legal = props?.menu?.find(({ node }) => {
    return node.url == "";
  });
  return (
    <div className="sub-footer">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <div className="sub-footer-inner d-flex justify-content-center">
              <div className="copyright">
                <span>
                  1995-{new Date().getFullYear()} © Inrete – {legal?.node?.name}
                </span>
                –{" "}
                {props.menu &&
                  props.menu.map(
                    ({ node }, index, array) =>
                      node.url != "" && (
                        <span key={index}>
                          <a href={node.url}>{node.name}</a>
                          {index < array.length - 1 && " –"}
                        </span>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
