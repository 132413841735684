import React from "react";
import SEO from "./SEO";
import Header from "./Header";
import Footer from "./Footer";
import SubFooter from "./SubFooter";
import "../scss/style.scss";

const Layout = (props) => (
  <>
    <SEO title={props.pageTitle} />
    <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ""}`}>
      <div id="wrapper" className="wrapper">
        <Header
          slug={props.slug}
          lang={props.lang}
          menu={props.menu && props.menu.main}
          service={props.menu && props.menu.service}
        />

        {props.children}
      </div>
      <Footer menu={props.menu && props.menu.footer} />
      <SubFooter menu={props.menu && props.menu.subFooter} />
    </div>
  </>
);

export default Layout;
