import React, { useState } from "react";
import { Link } from "gatsby";
import { iconService } from "../data/icon";
import Dropdown from "./Dropdown";

const isBrowser = typeof window !== "undefined";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);

  // let ref = useRef();

  // useEffect(() => {
  //   const handler = (event) => {
  //     if (dropdown && ref.current && !ref.current.contains(event.target)) {
  //       setDropdown(false);
  //     }
  //   };
  //   if (window !== "undefined") {
  //     document.addEventListener("mousedown", handler);
  //     document.addEventListener("touchstart", handler);
  //     return () => {
  //       // Cleanup the event listener
  //       document.removeEventListener("mousedown", handler);
  //       document.removeEventListener("touchstart", handler);
  //     };
  //   }
  // }, [dropdown]);

  const onMouseEnter = () => {
    if (window !== "undefined") {
      // dropdown && setDropdown(false);
      setDropdown(false);
      window.innerWidth > 960 && setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window !== "undefined") {
      // const newLocal = 15000;
      if (window.innerWidth > 960) {
        // setTimeout(() => {
        setDropdown(false);
        // }, 100);
      }
    }
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <div className="">
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.icon && (
              <span className="mr-1 pl-1 border-left border-primary">
                {" "}
                {iconService[items.icon]}
              </span>
            )}
            {isBrowser ? (
              window.innerWidth < 960 && depthLevel === 0 ? (
                items.name
              ) : (
                <Link to={items.url} activeClassName="activeLink">
                  {items.name}
                </Link>
              )
            ) : null}

            {isBrowser ? (
              depthLevel > 0 &&
              window !== "undefined" &&
              window.innerWidth < 960 ? null : depthLevel > 0 &&
                window !== "undefined" &&
                window.innerWidth > 960 ? (
                <span>&raquo;</span>
              ) : (
                <span className="float-right arrow" />
              )
            ) : null}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </div>
      ) : !items.url && items.submenu ? (
        <div>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.name}
            {depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <span className="float-right arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </div>
      ) : items.png_icon ? (
        <a href={items.url} target="_blank">
          <img className="service-icon mr-1" src={"/images/icon/" + items.png_icon} />

          {items.name}
        </a>
      ) : (
        <Link to={items.url} activeClassName="activeLink">
          {items.name}
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
