import React, { useState } from "react";
import { Link } from "gatsby";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import MenuMobile from "./MenuMobile";

const logo = (
  <svg
    width="180px"
    height="30px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 552 90.5"
  >
    <g id="Page-1">
      <path
        id="Combined-Shape"
        className="logo-fill"
        d="M166,0.3l-20,90L69.4,52L61,90.2h-5l15.9-69.8L76,0.2l76.7,38.4l8.8-38.3L166,0.3z
		 M361,0.2l-1.1,5h-35l-4.5,20H356l-1.1,5h-35.6l-12.2,55h35l-1.1,5h-75l20-90H361z M467,0.2l-1.1,5h-26l-18.9,85h-39.9l18.9-85H370
		l1.1-5H467z M551,0.2l-1.1,5h-35l-4.5,20H546l-1.1,5h-35.6l-12.2,55h35l-1.1,5h-75l20-90H551z M61,0.2l-20,90H1l20-90H61z
		 M221.3,0.3l0.4,0c34.2,0,49,17.9,43,40c-5.5,20.6-27.2,33.2-54.1,34.8c10.5,7.4,24.2,10.2,35.2,10.2l0,0l-5,5
		c-8.6,0-28.3-3.7-36.8-14.4l0,0l-3.1,14.4H161l20-90h39.9c0.3,0,0.5,0,0.8,0L221.3,0.3z M220.3,5.3h-0.5l-14.5,65
		c0.2,0,0.4,0,0.6,0c27.2,0,48.1-11.2,53.2-30C264.2,21.5,250.8,5.3,220.3,5.3L220.3,5.3z"
      />
    </g>
  </svg>
);

const Header = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { slug } = props;
  return (
    <div className="header fixed-top">
      <div className="container">
        <div className="logo">
          <Link to={props.lang == "default" ? "/" : `/${props.lang}`}>{logo}</Link>
        </div>
        <div className="logo-mobile">
          <Link to={props.lang == "default" ? "/" : `/${props.lang}`}>{logo}</Link>
        </div>

        <MenuMobile
          lang={props.lang}
          menu={props.menu}
          service={props.service}
          active={mobileOpen}
        />
        <Menu lang={props.lang} menu={props.menu} service={props.service} slug={slug} />
        <Hamburger toggleMenu={() => setMobileOpen(!mobileOpen)} />
      </div>
    </div>
  );
};

export default Header;
